import { GoogleCharts } from 'google-charts';
import { drawMonthlyChart } from './monthlyChart';
import { drawMonthlyTable } from './monthlyTable';
import { drawTotalChart } from './totalChart';

function drawSummary(annualTurnovers, totalTurnovers, turnoverType, divId, filter, filterUrl) {
  const data = new GoogleCharts.api.visualization.DataTable();
  data.addColumn('number', gettext('Month'));

  const rows = [
    [{ v: 1, f: gettext('January') }], [{ v: 2, f: gettext('February') }],
    [{ v: 3, f: gettext('March') }], [{ v: 4, f: gettext('April') }],
    [{ v: 5, f: gettext('May') }], [{ v: 6, f: gettext('June') }],
    [{ v: 7, f: gettext('July') }], [{ v: 8, f: gettext('August') }],
    [{ v: 9, f: gettext('September') }], [{ v: 10, f: gettext('October') }],
    [{ v: 11, f: gettext('November') }], [{ v: 12, f: gettext('December') }],
  ];

  const years = Object.keys(annualTurnovers);
  const totalRow = [
    {
      v: 13,
      f: gettext('Total'),
      p: {
        className: 'totalLabel',
      },
    },
  ];

  const sixMonthAverageRow = [
    {
      v: 13,
      f: gettext('Monthly Avg (last 6 months)'),
      p: {
        className: 'totalLabel',
      },
    },
  ];

  const yearAverageRow = [
    {
      v: 13,
      f: gettext('Monthly Avg (last 12 months)'),
      p: {
        className: 'totalLabel',
      },
    },
  ];

  years.forEach((year) => {
    data.addColumn('number', year);

    const annualTurnover = annualTurnovers[year];
    const last6Months = annualTurnovers[year].net.reduceRight((acc, item) => {
      if (item === 0 && acc.length === 0) {
        return acc;
      }
      return acc.concat(item);
    }, []).slice(0, 6);

    rows.forEach((row, rowIndex) => {
      row.push(annualTurnover.net[rowIndex]);
    });
    totalRow.push({
      v: totalTurnovers[year].net,
      p: { className: 'totalCell' },
    });
    sixMonthAverageRow.push({
      v: Math.round(last6Months.reduce((sum, v) => sum + v, 0) / last6Months.length || 1),
      p: { className: 'totalCell' },
    });
    yearAverageRow.push({
      v: Math.round(totalTurnovers[year].net / 12),
      p: { className: 'totalCell' },
    });
  });

  const formatterOptions = {
    prefix: '$',
    fractionDigits: 0,
    groupingSymbol: '.',
  };

  if (divId.includes('clf')) {
    formatterOptions.prefix = 'UF ';
  }

  const formatter = new GoogleCharts.api.visualization.NumberFormat(formatterOptions);

  data.addRows(rows);

  drawMonthlyChart(data, turnoverType, divId);

  for (let j = 1; j <= (data.getNumberOfColumns() - 1); j += 1) {
    formatter.format(data, j);
  }

  setTimeout(() => {
    data.addRow(totalRow);
    data.addRow(sixMonthAverageRow);
    data.addRow(yearAverageRow);
    for (let j = 1; j <= (data.getNumberOfColumns() - 1); j += 1) {
      formatter.format(data, j);
    }
    drawMonthlyTable(data, turnoverType, divId, filter, filterUrl);
  }, 100);
}

function renderError(divId, error) {
  const element = document.getElementById(divId.concat('_monthly_table_div'));
  element.innerHTML = `<div class="alert alert-danger">${error}</div>`;
}

function callAnnualTurnovers(baseApiUrl, divId, filter, filterUrl, callback) {
  const year = document.getElementById('year-input').value;
  const startYear = document.getElementById('year-start-input').value;
  const backupDate = document.getElementById('backup-date-input').value;
  const apiUrl = (
    `${baseApiUrl}?year=${year}&start_year=${startYear}&generation_date=${backupDate}`
  );
  fetch(apiUrl)
    .then((response) => {
      if (!response.ok) {
        renderError(divId, 'API fetch error');
        return 1;
      }

      return response.json().then((annualTurnovers) => {
        GoogleCharts.load(() => {
          drawSummary(
            annualTurnovers.annualTurnovers,
            annualTurnovers.totalTurnovers,
            'monthly',
            divId,
            filter,
            filterUrl,
          );

          drawSummary(
            annualTurnovers.cummulativeTurnovers,
            annualTurnovers.totalTurnovers,
            'cummulative',
            divId,
            filter,
          );

          drawTotalChart(
            annualTurnovers.totalTurnovers,
            divId,
            annualTurnovers.currency,
          );
        });
      });
    }).then(callback)
    .catch((error) => {
      renderError(divId, error);
    });
}

$(() => {
  if (!document.getElementById('turnoverNavTabs')) {
    return;
  }

  const calls = [{
    url: '/api/v1/annual-turnovers/',
    divId: 'real',
    filterBy: 'emission_date',
  }, {
    url: '/api/v1/annual-clf-turnovers/',
    divId: 'clf-real',
    filterBy: 'emission_date',
  }, {
    url: '/api/v1/projected-annual-turnovers/',
    divId: 'projected',
    filterBy: 'emission_date',
  }, {
    url: '/api/v1/projected-clf-annual-turnovers/',
    divId: 'projected-clf',
    filterBy: 'emission_date',
  }, {
    url: '/api/v1/annual-clp-sales-by-invoice/',
    divId: 'annual-clp-sales-by-invoice',
    filterBy: 'sale_date',
  }, {
    url: '/api/v1/annual-clf-sales-by-invoice/',
    divId: 'annual-clf-sales-by-invoice',
    filterBy: 'sale_date',
  }, {
    url: '/api/v1/annual-clf-sales/',
    divId: 'annual-clf-sales',
    filterBy: 'sale_date',
    filterUrl: '/projects/sales/',
  }, {
    url: '/api/v1/annual-clf-invoiced-sales/',
    divId: 'clf-invoiced-sales',
    filterBy: 'sale_date',
  }, {
    url: '/api/v1/annual-clp-payments-by-invoice/',
    divId: 'clp-invoiced-payments',
    filterBy: 'payment_date',
  }];

  let index = 0;
  function generateReport() {
    if (index < calls.length) {
      const call = calls[index];
      const filterUrl = call.filterUrl || '/etds/chilean-income-invoices/';
      index += 1;
      callAnnualTurnovers(call.url, call.divId, call.filterBy, filterUrl, generateReport);
    }
  }
  generateReport(0);
});
