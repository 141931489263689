export const options = {
  hAxis: {
    title: gettext('Month'),
    titleTextStyle: {
      color: '#575757',
      fontName: 'Gudea',
      fontSize: 14,
      bold: true,
      italic: false,
    },
    textStyle: {
      fontName: 'Gudea',
    },
  },
  vAxis: {
    title: gettext('Revenue'),
    titleTextStyle: {
      color: '#575757',
      fontName: 'Gudea',
      fontSize: 14,
      bold: true,
      italic: false,
    },
    textStyle: {
      fontName: 'Gudea',
    },
  },
  // Colors of years
  colors: ['#44B3E3', '#0083BA', '#7024FF', '#04B372'],
  chartArea: {
    backgroundColor: '#F0F1F2',
  },
  width: 1100,
  height: 400,
  title: gettext('Revenue by Month'),
  subtitle: '(CLP)',
  titleTextStyle: {
    color: '#575757',
    fontSize: 28,
    fontName: 'Sunflower',
    bold: true,
    italic: false,
  },
};
