import App from '../app';

/* eslint-env browser */

const select2UsedSelectors = [];

function connectSelects(selectors) {
  const childSelector = selectors.child;
  const $childSelect = $(childSelector);
  const parentSelectors = selectors.parents;
  const tags = $childSelect.hasClass('js-select2-tags');

  const lookups = selectors.lookupNames || [];

  if (!$childSelect.length) {
    return;
  }

  for (let i = 0; i < parentSelectors.length; i += 1) {
    const $parent = $(parentSelectors[i]);

    if ($parent.length === 0) {
      return;
    }

    $parent.change(() => {
      $childSelect.val(null).trigger('change');
    });

    if (lookups.length <= i) {
      lookups.push($parent.attr('name'));
    }
  }
  $childSelect.select2({
    width: '100%',
    ajax: {
      url: $childSelect[0].dataset.url,
      dataType: 'json',
      delay: 250,
      data: (params) => {
        const data = { ...selectors.extraData };

        parentSelectors.forEach((parentSelector, index) => {
          let value = $(parentSelector).val();
          if (value.join) {
            value = value.join(',');
          }
          data[lookups[index]] = value;
        });

        return {
          ...data,
          q: params.term,
        };
      },
      processResults: (data) => {
        if (data.results) {
          return data;
        }
        return { results: data };
      },
      cache: true,
    },
    tags,
    theme: 'bootstrap4',
  });

  // mark selects as already used for select2
  select2UsedSelectors.push(childSelector);
}

$(() => {
  // Declare conected selects
  const connectedSelectsData = [{
    parents: ['#id_person'],
    child: '#id_project_stages',
  }, {
    parents: ['#id_project_stages'],
    child: '#id_milestones',
    extraData: {
      chilean_income_invoice_id: $('#id_milestones').data('invoice-id'),
    },
  }];

  // and connect them
  connectedSelectsData.forEach((connectedSelectData) => {
    connectSelects(connectedSelectData);
  });

  setTimeout(() => {
    $('select')
      .not('.js-not-select2')
      .not('.api-field')
      .not(select2UsedSelectors.join())
      .each((i, select) => {
        const $select = $(select);
        const tags = $select.hasClass('js-select2-tags');
        $select.select2({
          theme: 'bootstrap4',
          tags,
        });
      });

    $('select.api-field')
      .not(select2UsedSelectors.join())
      .each((i, select) => {
        if (!select.hasAttribute('readonly')) {
          const $select = $(select);

          $select.select2({
            theme: 'bootstrap4',
            ajax: {
              url: select.dataset.url,
              processResults: (data) => {
                if (data.results) {
                  return data;
                }
                return { results: data };
              },
              data: (params) => {
                const siblingsToExclude = document.getElementsByClassName(
                  select.dataset.excludeSiblingsClass);
                const valuesToExclude = Array.from(siblingsToExclude)
                  .filter(input => !!input.value && input.value !== select.value)
                  .map(input => input.value);

                const form = select.closest('form');
                const dependenciesToInclude = document.getElementsByClassName(
                  select.dataset.dependenciesToIncludeClass);
                const formData = new FormData(form);
                const otherValues = Array.from(dependenciesToInclude)
                  .reduce((accumulator, input) => (
                    { ...accumulator, [input.name]: formData.get(input.name) }), {},
                  );

                const query = {
                  q: params.term,
                  page: params.page || 1,
                  exclude: valuesToExclude.join(','),
                  ...otherValues,
                };

                // Query parameters will be ?search=[term]&page=[page]&exclude=[ex]
                return query;
              },
              dataType: 'json',
              delay: 250,
              cache: true,
            },
          });
        }
      });
  }, 100);

  $('.model-form input:text').addClass('form-control');

  $('form').submit((e) => {
    const $this = $(e.currentTarget);
    const $buttons = $this.find(':submit').not('.js-do-not-disable-on-submit');

    // disable buttons after submit to prevent disabling submit inputs
    // with values
    setTimeout(() => {
      $buttons.addClass('disabled').prop('disabled', true);
      App.utils.showLoading($buttons);

      setTimeout(() => {
        $buttons.removeClass('disabled').prop('disabled', false);
        App.utils.hideLoading();
      }, 3000);
    }, 10);

    return true;
  });
});

export default connectSelects;
