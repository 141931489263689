import { GoogleCharts } from 'google-charts';
import { options } from './generalOptions';
import { getUrlFormattedDate } from '../utils/filterUtils';


export function drawMonthlyTable(data, turnoverType, divType, filter, filterUrl) {
  const tb = new GoogleCharts.api.visualization.Table(
    document.getElementById(turnoverType === 'monthly' ? divType.concat('_monthly_table_div') : divType.concat('_cummulative_table_div')),
  );


  GoogleCharts.api.visualization.events.addListener(tb, 'sort', () => {
    const parentRow = $(turnoverType === 'monthly' ? 'td'.concat(divType, 'MonthlyTotalLabel') : 'td'.concat(divType, 'CummulativeTotalLabel').parent());
    if (!parentRow.is(':last-child')) {
      parentRow.siblings().last().after(parentRow);
    }
  });

  GoogleCharts.api.visualization.events.addListener(tb, 'ready', () => {
    const container = tb.getContainer();

    function selectCell(sender) {
      const cell = sender.target;
      const row = cell.closest('tr');

      const selectedRow = row.rowIndex - 1;
      const selectedCol = cell.cellIndex;
      let firstMonth;
      let nextMonth;

      if (selectedRow < 12) {
        firstMonth = selectedRow;
        nextMonth = selectedRow + 1;
      } else if (selectedRow >= 12) {
        // complete year was selected
        firstMonth = 0;
        nextMonth = 12;
      }

      const year = parseInt(data.getColumnLabel(selectedCol), 10);
      const firstDay = new Date(year, firstMonth, 1);
      const lastDay = new Date(year, nextMonth, 0);

      const firstDayStr = getUrlFormattedDate(firstDay);
      const lastDayStr = getUrlFormattedDate(lastDay);

      const url = `${filterUrl}?${filter}__gte=${firstDayStr}&${filter}__lte=${lastDayStr}`;
      window.open(url, '_blank').focus();
    }

    Array.prototype.forEach.call(container.getElementsByTagName('TD'), (cell) => {
      cell.addEventListener('click', selectCell);
    });
  });

  const cssClassNames = {
    headerRow: 'cssHeaderRow',
    tableRow: 'cssTableRow',
    oddTableRow: 'cssOddTableRow',
    selectedTableRow: 'cssSelectedTableRow',
    hoverTableRow: 'cssHoverTableRow',
    hoverHeaderRow: 'cssHoverHeaderRow',
    headerCell: 'cssHeaderCell',
    tableCell: 'cssTableCell cursor-pointer',
  };

  const tableOptions = {
    ...options,
    cssClassNames,
  };

  // Display DataTable
  tb.draw(data, tableOptions);
}
