import 'datatables.net-bs4';

const formatter = new Intl.NumberFormat('de-DE');

function createRow(movement) {
  const debitAmount = formatter.format(movement.debitAmountClp);
  const creditAmount = formatter.format(movement.creditAmountClp);
  let documentDescription;
  if (movement.documentId) {
    documentDescription = `
      <a href="/documents/${movement.documentId}">
        ${movement.description}
      </a>`;
  } else {
    documentDescription = movement.description;
  }

  const row = `
    <tr>
        <td>${documentDescription}</td>
        <td class="text-nowrap">$ ${debitAmount}</td>
        <td class="text-nowrap">$ ${creditAmount}</td>
    </tr>
    `;
  return row;
}

function format(rowData) {
  const { movements } = rowData;
  const rowsArray = movements.map(movement => createRow(movement));
  const rows = rowsArray.join('\n');
  const str = `
    <table class="table table-striped">
        <thead>
            <th> Description </th>
            <th> Income </th>
            <th> Outcome </th>
        </thead>
        <tbody>
            ${rows}
        </tbody>
    </table>
  `;
  return str;
}

function enhanceTodayRows() {
  // Scroll today into view
  const todayRealRow = document.getElementsByClassName('today')[0];
  todayRealRow.scrollIntoView({
    behavior: 'auto',
    block: 'center',
    inline: 'center',
  });
  const today = todayRealRow.getElementsByClassName('date')[0].innerHTML;
  const todayBalanceCell = todayRealRow.getElementsByClassName('balance')[0];
  todayBalanceCell.innerHTML = (
    `<a href="/accounting-accounts/checking-accounts/" target="_blank">
      <i class="fas fa-info-circle fa-xs"></i>
      ${todayBalanceCell.innerHTML}
    </a>`
  );
  $(todayBalanceCell).find('a').popover({
    title: 'Balance real en CLP',
    content: 'Dinero total en CLP en todas las cuentas corrientes',
    trigger: 'hover',
  });

  // Scroll today into view
  const todayExpectedBalanceCell = document.querySelectorAll('.today .balance')[1];
  todayExpectedBalanceCell.innerHTML = (
    `<span>
      <i class="fas fa-info-circle fa-xs"></i>
      ${todayExpectedBalanceCell.innerHTML}
    </span>`
  );
  $(todayExpectedBalanceCell).find('span').popover({
    title: 'Balance esperado en CLP',
    content: 'Suma de dinero esperada total en CLP al final día en todas las cuentas corrientes',
    trigger: 'hover',
  });

  const todayInvoiceReceivableCell = document.querySelectorAll('.today .invoices-receivable')[0];
  todayInvoiceReceivableCell.innerHTML = (
    `<a href="/etds/chilean-income-invoices/?is_issued=ISSUED&is_paid=UNPAID" target="_blank">
      <i class="fas fa-info-circle fa-xs"></i>
      ${todayInvoiceReceivableCell.innerHTML}
    </a>`
  );
  $(todayInvoiceReceivableCell).find('a').popover({
    title: 'Cuentas por cobrar',
    content: 'Cuentas por cobrar solo considerando las facturas emitidas',
    trigger: 'hover',
  });

  const expectedInvoiceReceivableCell = document.querySelectorAll('.today .invoices-receivable')[1];
  expectedInvoiceReceivableCell.innerHTML = (
    `<a href="/etds/chilean-income-invoices/?emission_date__gte=${today}&emission_date__lte=${today}&is_issued=UNISSUED" target="_blank">
      <i class="fas fa-info-circle fa-xs"></i>
      ${expectedInvoiceReceivableCell.innerHTML}
    </a>`
  );
  $(expectedInvoiceReceivableCell).find('a').popover({
    title: 'Cuentas por cobrar esperadas',
    content: 'Cuentas por cobrar incluyendo lo que se emitirá hoy',
    trigger: 'hover',
  });
}

async function getCashflow(pastMonths, futureMonths) {
  return fetch(`/api/v1/cashflow/?past_months=${pastMonths}&future_months=${futureMonths}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error('API fetch error');
      }
      return response.json().then(cashflow => cashflow.data);
    }).catch((error) => {
      window.alert(error); // eslint-disable-line no-alert
    });
}

$(async () => {
  if (document.getElementsByClassName('cashflow-table').length === 0) {
    return;
  }
  const urlParams = new URLSearchParams(window.location.search);
  const pastMonths = urlParams.get('past_months');
  const futureMonths = urlParams.get('future_months');
  const data = await getCashflow(pastMonths, futureMonths);
  const table = $('table.cashflow-table').DataTable({
    createdRow(row, rowData) {
      if (rowData.balance < 0) {
        $(row).addClass('table-danger');
      }
      if (rowData.today) {
        $(row).addClass('table-success today');
      }
    },
    data,
    language: {
      thousands: '.',
    },
    columns: [{
      data: 'date',
      className: 'columnRight date',
    },
    {
      data: 'debitAmountClp',
      render: $.fn.dataTable.render.number('.', ',', 0, '$ '),
      className: 'columnRight text-success',
    },
    {
      data: 'creditAmountClp',
      render: $.fn.dataTable.render.number('.', ',', 0, '$ '),
      className: 'columnRight text-danger',
    },
    {
      data: 'balance',
      render: $.fn.dataTable.render.number('.', ',', 0, '$ '),
      className: 'columnRight balance',
    },
    {
      data: 'invoicesReceivable',
      render: $.fn.dataTable.render.number('.', ',', 0, '$ '),
      className: 'columnRight invoices-receivable',
      createdCell(td, cellData, rowData) {
        if (cellData > Math.abs(rowData.balance) && rowData.balance < 0) {
          $(td).addClass('text-success');
        }
      },
    },
    ],
    paging: false,
  });

  enhanceTodayRows();

  $('table.cashflow-table tbody').on('click', 'td', function update() {
    const tr = $(this).closest('tr');
    const row = table.row(tr);
    const rowData = row.data();
    if (rowData.movements.length !== 0) {
      if (row.child.isShown()) {
        row.child.hide();
        tr.removeClass('shown');
      } else {
        row.child(format(rowData)).show();
        tr.addClass('shown');
      }
    }
  });
});
