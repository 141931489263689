const App = {};

App.utils = {
  hideLoading() {
    $('body').removeClass('wait');
  },

  thousandSeparator(x) {
    const integer = Math.floor(x);
    const integerText = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    if (integer === x) {
      return integerText;
    }

    const floatText = x.toString().replace(`${integer.toString()}.`, '');

    return `${integerText},${floatText}`;
  },

  showLoading($element) {
    $('body').addClass('wait');

    if ($element && $element.find('.page-loading-icon').length === 0) {
      $element.append(
        '<i class="fa fa-spinner fa-spin page-loading-icon"></i>',
      );
    }
  },

  highlight($element) {
    $element.addClass('highlight');

    setTimeout(() => {
      $element.toggleClass('dim highlight');
    }, 15);

    setTimeout(() => $element.removeClass('dim'), 1010);
  },
};


export default App;
