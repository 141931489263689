import { GoogleCharts } from 'google-charts';
import { options } from './generalOptions';


export function drawMonthlyChart(data, turnoverType, divType) {
  const graphOptions = {
    ...options,
    lineWidth: 4,
    hAxis: {
      ...options.hAxis,
      ticks: [
        { v: 1, f: gettext('Jan') }, { v: 2, f: gettext('Feb') },
        { v: 3, f: gettext('Mar') }, { v: 4, f: gettext('Apr') },
        { v: 5, f: gettext('May') }, { v: 6, f: gettext('Jun') },
        { v: 7, f: gettext('Jul') }, { v: 8, f: gettext('Aug') },
        { v: 9, f: gettext('Sep') }, { v: 10, f: gettext('Oct') },
        { v: 11, f: gettext('Nov') }, { v: 12, f: gettext('Dec') },
      ],
    },
    vAxis: {
      ...options.vAxis,
      format: '$#,###',
    },
    legend: {
      textStyle: {
        fontName: 'Gudea',
      },
    },
    tooltip: {
      textStyle: {
        fontName: 'Gudea',
      },
    },
    title: turnoverType === 'monthly' ? gettext('Revenue by Month') : gettext('Cummulative Revenue by Month'),
  };

  const chart = new GoogleCharts.api.visualization.LineChart(
    document.getElementById(turnoverType === 'monthly' ? divType.concat('_monthly_chart_div') : divType.concat('_cummulative_chart_div')),
  );
  chart.draw(data, graphOptions);
}
