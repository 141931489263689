import 'datatables.net-bs4';

const PADDING = 15;

function createRow(rowData, depth) {
  const { children } = rowData;
  const toggleClass = children.length !== 0 ? 'toggle' : '';
  const icon = children.length !== 0 ? '<i class="fa fa-angle-down"></i>' : '';
  const rowsArray = children.map(child => createRow(child, depth + 1));
  const rows = rowsArray.join('\n');
  const str = `
    <tr data-depth=${depth} class="cr-collapse ${toggleClass}">
      <td style="padding-left: ${PADDING * depth}px">
        ${icon}
        <a href="/cost-centres/${rowData.id}" class="padding-left:5px">
          ${rowData.name}
        </a>
      </td>
      <td>${rowData.startingDate}</td>
      <td>${rowData.finishDate}</td>
      <td>${rowData.usedBudget}%</td>
    </tr>
    ${rows}
  `;
  return str;
}

function createTable(data) {
  const rows = data.map(d => createRow(d, 0)).join('\n');
  return `<tbody>${rows}</tbody>`;
}

async function getCostCentres() {
  let response;
  try {
    response = await fetch('/api/v1/costcentres');
    if (!response.ok) {
      throw new Error('API fetch error');
    }
  } catch (error) {
    window.alert(error); // eslint-disable-line no-alert
  }
  return response.json();
}

$(async () => {
  const tables = document.getElementsByClassName('tree-cost-centre-table');
  if (tables.length === 0) {
    return;
  }

  const table = tables[0];
  const { data } = await getCostCentres();
  $(table).append($.parseHTML(createTable(data)));

  $(table).on('click', '.toggle', (event) => {
    // Gets all <tr>'s  of greater depth below element in the table
    const findChildren = (tr) => {
      const depth = tr.data('depth');
      return tr.nextUntil($('tr').filter((_, item) => $(item).data('depth') <= depth));
    };

    const tr = $(event.target).closest('tr'); // Get <tr> from the clicked item
    let children = findChildren(tr);

    // Remove already collapsed nodes from children so that we don't
    // make them visible.
    const subnodes = children.filter('.cr-expand');
    subnodes.each((_, node) => {
      const subnode = $(node);
      const subnodeChildren = findChildren(subnode);
      children = children.not(subnodeChildren);
    });

    // hide/show children
    const svgIcon = tr.children().find('svg');
    if (tr.hasClass('cr-collapse')) {
      svgIcon.removeClass('fa-angle-down').addClass('fa-angle-right');
      tr.removeClass('cr-collapse').addClass('cr-expand');
      children.hide();
    } else {
      svgIcon.removeClass('fa-angle-right').addClass('fa-angle-down');
      tr.removeClass('cr-expand').addClass('cr-collapse');
      children.show();
    }
    return children;
  });
});
