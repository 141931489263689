$(() => {
  $('.delete-date-btn').hide();
  $('.reported-payment-date-input').each((i, item) => {
    const $this = $(item);
    const $btn = $this.find('.delete-date-btn');
    const csrftoken = $('[name=csrfmiddlewaretoken]').val();

    function postDate() {
      const url = (
        `/etds/client/${$this.data('clientKey')}/`
        + `unpayed-invoices/${$this.data('objId')}/report-payment-date/`
      );
      const value = $this.find('input').val();
      if (value) {
        $btn.show();
      } else {
        $btn.hide();
      }
      $.ajax({
        url,
        type: 'POST',
        data: {
          reported_payment_date: $this.find('input').val(),
        },
        beforeSend: (xhr) => {
          xhr.setRequestHeader('X-CSRFToken', csrftoken);
        },
        success: () => {
          if (value) {
            $(`#row-status-${$this.data('objId')}`).html(
              '<i class="fas fa-check text-success"></i>',
            );
          } else {
            $(`#row-status-${$this.data('objId')}`).html('');
          }
        },
        error: () => {
          $(`#row-status-${$this.data('objId')}`).html(
            '<i class="fas fa-exclamation-triangle text-danger"></i>',
          );
        },
      });
    }

    if ($this.find('input').val() === '') {
      $btn.hide();
    } else {
      $btn.show();
    }
    $this.on('hide.datetimepicker', postDate);
    $btn.click(() => {
      $this.datetimepicker('clear');
      postDate();
    });
  });
});
