function documentSummaryView(el) {
  const summaryDisplay = el;
  const { inputIds } = summaryDisplay.dataset;

  if (inputIds) {
    const $input = $(`#${inputIds.split(',').filter(n => n)}`.replace(',', ',#'));

    const callback = () => {
      const selectedMovementId = $input.val();
      if (selectedMovementId) {
        fetch(`/movements/${selectedMovementId}/summary/`)
          .then((response) => {
            if (!response.ok) {
              summaryDisplay.innerHTML = 'Error al buscar los datos';
            }
            return response.text();
          }).then((html) => {
            summaryDisplay.innerHTML = html;
          }).catch(() => {
            summaryDisplay.innerHTML = 'Error al buscar los datos';
          });
      }
    };
    $input.change(callback);
    callback();
  }
}

$(() => {
  const summaryDisplays = document.getElementsByClassName(
    'document-summary-display',
  );
  let i;

  for (i = 0; i < summaryDisplays.length; i += 1) {
    documentSummaryView(summaryDisplays[i]);
  }
});
