function joinGreaterLessThanPair(greaterThanId, lessThanId) {
  const greaterThanInput = document.getElementById(greaterThanId);
  if (greaterThanInput == null) {
    return;
  }
  const lessThanInput = document.getElementById(lessThanId);
  if (lessThanInput == null) {
    return;
  }

  greaterThanInput.addEventListener('change', () => {
    const { value } = greaterThanInput;
    if (value !== '' && value > lessThanInput.value) {
      lessThanInput.value = value;
    }
  });

  lessThanInput.addEventListener('change', () => {
    const { value } = lessThanInput;
    if (value !== '' && value < greaterThanInput.value) {
      greaterThanInput.value = value;
    }
  });
}


function joinNumericInputs() {
  // pairs of greater and less than inputs
  const numericInputPairs = [
    ['id_total_amount__gte', 'id_total_amount__lte'],
  ];

  for (let i = 0; i < numericInputPairs.length; i += 1) {
    joinGreaterLessThanPair(numericInputPairs[i][0], numericInputPairs[i][1]);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  joinNumericInputs();
}, false);
