$(() => {
  $('.datetimepicker-input').each((i, item) => {
    const $this = $(item);
    if (!$this.data('target')) {
      $this.data('target', `#${$this.prop('id')}`);
    }

    $this.datetimepicker({
      date: moment($this.val(), 'YYYY-MM-DD'),
      minDate: $this.data('minDate'),
      maxDate: $this.data('maxDate'),
      format: $this.data('format') || 'YYYY-MM-DD',
      locale: 'es',
    });
  });

  $('.datetimepicker-group').each((i, item) => {
    const $this = $(item);
    $this.find('input').addClass('datetimepicker-input');

    const date = moment($this.find('input').val(), 'YYYY-MM-DD');
    $this.datetimepicker({
      date: date.isValid() ? date : null,
      format: $this.data('format') || 'YYYY-MM-DD',
      locale: 'es',
    });
  });
});
