import 'jquery';

function parseAmount(amountStr) {
  const formattedStr = amountStr.replaceAll('.', '').replace(',', '.');
  return parseFloat(formattedStr, 10);
}

function checkAssignment(inputAmount, referenceAmount) {
  if (inputAmount <= referenceAmount && inputAmount > 0) {
    return true;
  }
  return false;
}


$(() => {
  const addButton = $('#add-form');
  const selectors = $('select.form-control');
  let assignmentForm = $('.assignment-form');
  let amountInputs = $('.assignment-form input[type=number]');
  const submitButton = $('#assign-btn');
  const amountAlertDiv = $('#amount-alert-div');
  const amountAlert = $('#amount-alert');
  let movementAmount = parseAmount($('#movement-amount').text());
  movementAmount -= parseAmount($('#assigned-amount').text());

  const totalForms = document.querySelector('#id_form-TOTAL_FORMS');

  let formNum = assignmentForm.length - 1;

  amountInputs.on('input', () => {
    let totalSum = 0;
    assignmentForm.each(function sumInputs() {
      $(this).find('input[type=number]').each((i, item) => {
        totalSum += parseFloat($(item).val(), 10);
      });
      if (checkAssignment(totalSum, movementAmount)) {
        submitButton.prop('disabled', false);
        amountAlertDiv.removeClass(['alert', 'alert-danger']);
        amountAlert.text('');
      } else {
        submitButton.prop('disabled', true);
        amountAlertDiv.addClass(['alert', 'alert-danger']);
        amountAlert.text('Assigned movement amount exceeded');
      }
    });
  });

  addButton.on('click', () => {
    selectors.select2('destroy');
    const newForm = assignmentForm.first().clone(true);
    const formRegex = RegExp('form-(\\d){1}-', 'g');

    formNum += 1;

    newForm[0].innerHTML = newForm[0].innerHTML.replace(formRegex, `form-${formNum}-`);
    $('#forms-table tr:last').after(newForm);

    totalForms.setAttribute('value', `${formNum + 1}`);
    selectors.select2();

    amountInputs = $('.assignment-form input[type=number]');
    assignmentForm = $('.assignment-form');
    amountInputs.off('input');
    amountInputs.on('input', () => {
      let totalSum = 0;
      assignmentForm.each(function sumInputs() {
        $(this).find('input[type=number]').each((i, item) => {
          totalSum += parseFloat($(item).val(), 10);
        });
      });
      if (checkAssignment(totalSum, movementAmount)) {
        submitButton.prop('disabled', false);
        amountAlertDiv.removeClass(['alert', 'alert-danger']);
        amountAlert.text('');
      } else {
        submitButton.prop('disabled', true);
        amountAlertDiv.addClass(['alert', 'alert-danger']);
        amountAlert.text('Assigned movement amount not valid');
      }
    });
  });
});
