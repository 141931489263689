import { GoogleCharts } from 'google-charts';

const billingHealthId = 'billing-health';

function stringToDate(str) {
  const parts = str.split('-');

  // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
  // January - 0, February - 1, etc.
  return new Date(parts[0], parts[1] - 1, parts[2]);
}

function drawChart(billingHealth) {
  const data = new google.visualization.DataTable();
  data.addColumn('date', 'Day');
  data.addColumn('number', 'KPI');
  data.addColumn({ type: 'boolean', role: 'certainty' }); // certainty col.
  data.addColumn('number', 'Month average');
  data.addColumn({ type: 'string', role: 'annotation' });
  data.addColumn({ type: 'string', role: 'annotationText' });

  const parsedBillingHealth = billingHealth.map((row) => {
    const date = stringToDate(row[0]);
    const today = new Date();
    const certainty = date < today;
    if (date.toDateString() === today.toDateString()) {
      return [date, row[1], true, row[2], '', 'today'];
    }

    return [date, row[1], certainty, row[2], null, null];
  });

  data.addRows(parsedBillingHealth);

  const options = {
    annotations: {
      style: 'line',
    },
    curveType: 'none',
    height: 400,
    legend: { position: 'bottom' },
    series: {
      0: { lineWidth: 2 },
      1: { lineWidth: 1 },
      2: { color: '#f1ca3a' },
    },
    title: 'Billing health',
  };

  const chart = new google.visualization.LineChart(
    document.getElementById(billingHealthId),
  );

  chart.draw(data, options);
}

function getBillingHealth() {
  fetch('/api/v1/invoices/billing-health/')
    .then((response) => {
      if (!response.ok) {
        throw new Error('API fetch error');
      }

      return response.json().then((billingHealth) => {
        GoogleCharts.load(() => {
          drawChart(billingHealth);
        });
      });
    }).catch((error) => {
      window.alert(error); // eslint-disable-line no-alert
    });
}

$(() => {
  if (!document.getElementById('billing-health')) {
    return;
  }

  getBillingHealth();
});
