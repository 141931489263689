import App from '../app';
import { getCLFValueinCLP } from '../utils/currency';

document.addEventListener('DOMContentLoaded', () => {
  const milestoneFormset = document.getElementById('milestone-formset');
  if (!milestoneFormset) {
    return;
  }

  const dateInput = document.getElementById('id_emission_date');
  const formsetRows = document.getElementsByClassName('formset-row');
  let clfValue;

  function updateCLFMilestonesValues() {
    let totalMilestoneCLF = 0;
    let totalMilestoneCLP = 0;

    const extraHelpTotalAmount = document.querySelector(
      '.extra-help-total_amount span',
    );

    const totalAmount = document.getElementById('id_total_amount').value;

    const totalNetCLP = parseInt(
      extraHelpTotalAmount ? extraHelpTotalAmount.innerHTML.replaceAll('.', '') : totalAmount,
      10);

    if (document.getElementsByClassName('milestone').length === 1) {
      document.getElementById(
        'id_invoice_contributions-0-total_amount_before_tax_on_emission',
      ).value = totalNetCLP;
    }

    for (let i = 0; i < formsetRows.length; i += 1) {
      const formsetRow = formsetRows.item(i);
      const input = formsetRow.querySelector(`.formset-row-input-${i} input`);
      const output = document.querySelector(`.formset-row-output-${i}`);
      const clpValue = parseInt(input.value, 10) || 0;
      const milestoneCLF = Math.round(
        (clpValue / clfValue + Number.EPSILON) * 100,
      ) / 100;
      totalMilestoneCLP += clpValue;
      totalMilestoneCLF += parseFloat(milestoneCLF, 10);
      output.innerHTML = App.utils.thousandSeparator(milestoneCLF);
    }
    document.getElementById('net-clp-milestone-total').innerHTML = App.utils.thousandSeparator(
      Math.round(totalMilestoneCLP),
    );
    document.getElementById('net-clf-milestone-total').innerHTML = App.utils.thousandSeparator(
      Math.round((totalMilestoneCLF + Number.EPSILON) * 100) / 100,
    );
    document.getElementById('net-clp-total').innerHTML = App.utils.thousandSeparator(
      totalNetCLP,
    );

    const totalNetCLF = Math.round(
      (totalNetCLP / clfValue + Number.EPSILON) * 100,
    ) / 100;
    document.getElementById('net-clf-total').innerHTML = App.utils.thousandSeparator(
      totalNetCLF,
    );

    document.getElementById('net-clp-diff').innerHTML = App.utils.thousandSeparator(
      totalNetCLP - totalMilestoneCLP,
    );
  }

  function inputChanged(e) {
    updateCLFMilestonesValues(e.target);
  }

  for (let i = 0; i < formsetRows.length; i += 1) {
    const formsetRow = formsetRows.item(i);
    const input = formsetRow.querySelector(`.formset-row-input-${i}`);
    input.addEventListener('change', inputChanged);
    input.addEventListener('keyup', inputChanged);
  }

  document.getElementById('id_total_amount').addEventListener('change', updateCLFMilestonesValues);

  $(dateInput).on('change.datetimepicker', () => {
    const date = dateInput.value.slice(0, 10);
    getCLFValueinCLP(date, (newCLFValue) => {
      clfValue = newCLFValue;
      updateCLFMilestonesValues();
    });
  }).trigger('change.datetimepicker');
}, false);
