import 'jquery-confirm';

const baseData = {
  title: 'Warning!',
  type: 'red',
  icon: 'fas fa-exclamation-triangle',
  buttons: {
    accept() {
      this.$target.closest('form').submit();
    },
    cancel() {},
  },
};

$(() => {
  $('.close-card').confirm({
    ...baseData,
    content: 'Do you want to close this credit card?',
  });

  $('.close-checking-account').confirm({
    ...baseData,
    content: 'Do you want to close this checking account?',
  });

  $('.unassign-cost-centre').confirm({
    ...baseData,
    content: 'Do you want to unassign this movement?',
  });
});
