import InputHelpTextHandler from '../components/input-help-text';
import App from '../app';

$(() => {
  const nameSelector = '#id_name';
  const personSelector = '#id_person';
  const inputHelpTextHandler = new InputHelpTextHandler(nameSelector);

  function nameChanged() {
    const personNameAndRut = $(`${personSelector} option:selected`).text();
    const personName = personNameAndRut.substring(0, personNameAndRut.lastIndexOf(' '));
    const $nameInput = $(nameSelector);
    inputHelpTextHandler.updateText(`${personName} - ${$nameInput.val()}`);
  }

  $(nameSelector).keyup(nameChanged);
  $(personSelector).change(nameChanged);
  $(nameSelector).keyup();
});

$(() => {
  const totalAmountId = 'id_total_amount';
  const currencyId = 'id_currency';
  const taxApplicableId = 'id_tax_applicable';
  const taxAmountId = 'id_tax_amount';
  const taxableAmountId = 'id_taxable_amount';
  const amountHelpTextHandler = new InputHelpTextHandler(`#${totalAmountId}`);

  function setAmountsHelpTexts() {
    const taxApplicableInput = document.getElementById(taxApplicableId);
    if (taxApplicableInput === null) {
      return;
    }
    const isVatApplicable = taxApplicableInput.checked || taxApplicableInput.value === 'True';
    const taxableAmount = parseFloat(document.getElementById(taxableAmountId).value, 10) || 0;
    const taxAmount = parseFloat(document.getElementById(taxAmountId).value, 10) || 0;
    const currency = document.getElementById(currencyId).value;

    if (isVatApplicable) {
      const netText = `Neto: <span>${App.utils.thousandSeparator(taxableAmount)} </span>${currency}`;
      const vatText = isVatApplicable ? `Tax: ${App.utils.thousandSeparator(taxAmount)} ${currency}` : '';
      amountHelpTextHandler.updateText(`${netText}<br>${vatText}`);
    } else {
      amountHelpTextHandler.updateText('');
    }
  }

  // Place all non jquery code here
  function handleTaxApplicableChanged() {
    const taxApplicableInput = document.getElementById(taxApplicableId);
    const taxAmountInput = document.getElementById(taxAmountId);
    const taxableAmountInput = document.getElementById(taxableAmountId);
    const currency = document.getElementById(currencyId).value;
    const totalAmount = parseFloat(document.getElementById(totalAmountId).value, 10) || 0;

    if (taxApplicableInput.checked || taxApplicableInput.value === 'True') {
      const taxRate = parseFloat(taxApplicableInput.dataset.defaultTaxRate);

      let taxAmount = totalAmount * taxRate / (1 + taxRate);

      if (currency === 'CLP') {
        taxAmount = Math.round(taxAmount);
      } else {
        // multiply by 100 and divide by 100 to round by to decimals
        taxAmount = Math.round((taxAmount + Number.EPSILON) * 100) / 100;
      }
      taxAmountInput.value = taxAmount;
      taxableAmountInput.value = Math.round((totalAmount - taxAmount) * 100) / 100;
    } else {
      taxAmountInput.value = 0;
      taxableAmountInput.value = 0;
    }
    setAmountsHelpTexts();
  }

  const taxAmountInput = document.getElementById(taxAmountId);
  const taxApplicableInput = document.getElementById(taxApplicableId);

  if (taxApplicableInput === null || taxAmountInput === null || taxAmountInput.disabled) {
    return;
  }

  const totalAmountInput = document.getElementById(totalAmountId);

  taxApplicableInput.addEventListener('change', handleTaxApplicableChanged, false);
  totalAmountInput.addEventListener('change', handleTaxApplicableChanged, false);
  totalAmountInput.addEventListener('keyup', handleTaxApplicableChanged, false);
  // use jquery to listen for changes since we are using select2
  $(`#${currencyId}`).change(handleTaxApplicableChanged);

  handleTaxApplicableChanged();
});
