class InputHelpTextHandler {
  constructor(inputSelector) {
    this.$input = $(inputSelector);
    this.$help = $(`.extra-help-${this.$input.attr('name')}`);
  }

  updateText(text) {
    this.$help.html(text);
  }
}

export default InputHelpTextHandler;
